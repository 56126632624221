.bottom {
	z-index: 5;
	width: 100%;
	bottom: 0;
	left: 0;
	position: fixed;
	height: em(54);
	background: $gray5;
	@media only screen and (max-width: 350px) {
		font-size: 14px;
	}
	.container {
		display: flex;
		//justify-content: space-between;
		align-items: center;
		height: em(54);
	}
	.icon-word {
		color: $gray1;
		margin-right: em(21);

		
		
		&:nth-child(3) {
			margin-left: auto;
		}
	}
}

.breadcrumb {
	//margin-top: 8px;
	padding-top: 8px;
	&>span {
		font-size: 12px;
		line-height: 15px;
		color: $gray3;
		text-decoration: none;
		a {
			color: $gray3;
			text-decoration: none;
		}
	}
	.delimiter {
		width: auto;
		margin-left: 5px;
		margin-right: 5px;
	}
}



.footer {
  background: #CCCCCC;
  @media only screen and (max-width: 768px) {
    //display: none;
  }
  
  @media only screen and (max-width: 930px) {
    font-size: 14px;
  }
  
  &_product {
    @media only screen and (max-width: 768px) {
      margin-bottom: em(119);
    }
  }
  
  .container {
    display: flex;
    //align-items: center;
    min-height: em(150);
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  &__logo {
    //margin-right: em(65);
    margin-right: em(53);
    align-self: center;
  
    @media only screen and (max-width: 820px) {
      margin-right: em(20);
    }
  
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  
  //region Меню
  &__menu-wrapper {
    display: flex;
    padding-top: em(22);
    @media only screen and (max-width: 768px) {
      min-width: 100%;
      justify-content: center;
    }
  }
  &__menu {
    margin-right: em(65);
    font-size: em(12);
    font-weight: 300;
  
    @media only screen and (max-width: 820px) {
      margin-right: em(20);
    }
    @media only screen and (max-width: 768px) {
      margin-right: em(30);
    }
    @media only screen and (max-width: 400px) {
      margin-right: rem(15);
      flex: 1;
    }
    
    &:last-child {
      margin-right: 0;
    }
  }
  &__title {
    display: block;
    font-weight: bold;
    text-decoration: none;
    color: #000000;
    margin-bottom: em(14);
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    
    
    li {
      margin-bottom: 4px;
      a {
        text-decoration: none;
        color: #000000;
      }
    }
  }
  //endregion
  
  //region Правая колонка
  &__right {
    margin-left: auto;
    padding-top: em(20);
    @media only screen and (max-width: 768px) {
      min-width: 100%;
      justify-content: center;
    }
  }
  
  &__socials {
    margin-bottom: em(14);
    @media only screen and (max-width: 768px) {
      text-align: center;
    }
    a {
      display: inline-block;
      @include mmright(em(12));
    }
  }
  
  &__copy {
    font-size: em(12);
    font-weight: 300;
    line-height: 140%;
    @media only screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: rem(28);
  
    }
    br {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  //endregion
  
}

.header {
  background: $gray2;
  
  @media only screen and (max-width: 768px) {
    background: #fff;
  }
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: em(70);
    @media only screen and (max-width: 768px) {
      min-height: 0;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  
  &__logo {
    @media only screen and (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }
    img {
    
    }
    
    &-desktop {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
  }
  
  &__search {
    display: flex;
    //margin-top: em(10);
    margin-left: em(100);
    margin-right: em(100);
    //margin-right: auto;
    justify-self: flex-start;
    flex: 1;
    position: relative;
  
    @media only screen and (max-width: 768px) {
      position: absolute;
      right: 0;
      background: #fff;
      margin-left: 0;
      margin-right: 0;
      width: em(48);
      
      @include trn();
    }
    
    &.js-active {
      width: 100%;
      input {
        opacity: 1;
      }
    }
    
    &:before {
      content: '';
      display: block;
      background: url(../img/icon_search.svg) no-repeat;
      width: em(19);
      height: em(19);
      margin-right: em(15);
      margin-top: em(5);
      position: absolute;
      @media only screen and (max-width: 768px) {
        right: 0;
        background-image: url(../img/icon_search_dark.svg);
  
      }
    }
    
    input {
      width: 100%;
      padding-bottom: 3px;
      background: none;
      border: none;
      outline: none;
      border-bottom: 1px solid transparent;
      color: $gray4;
      font-size: em(22);
      margin-left: em(25);
      @include trn();
  
      @media only screen and (max-width: 768px) {
        border-bottom-color: #000;
        margin-right: 48px;
        margin-left: 15px;
        opacity: 0;
        font-size: em(16);
      }
      
      @include placeholder {
        font-size: 12px;
        line-height: 50px;
        
      }
      
      &:hover {
      
      }
      &:focus {
        &+.header__search-border {
          width: 94%;
          width: calc(100% - 34px);
        }
      }
    }
    
    &-border {
      display: block;
      width: 0;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: -2px;
      margin-left: 34px;
      background: $gray3;
      //transform: scale(0);
      @include trn();
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    
    &-close {
      display: none;
      background: url(../img/icon_close.svg) no-repeat;
      width: 30px;
      height: 30px;
      @include trn();
      &:hover {
        transform: rotate(90deg);
      }
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
    
  }
  
  &__actions {
    @media only screen and (max-width: 768px) {
      display: none;
    }
    .icon-word {
      @include mmright(20px);
    }
  }
  
  &__burger-icon {
    width: 15px;
    position: relative;
    //background: #000;
    display: none;
  
    @media only screen and (max-width: 768px) {
      display: block;
    }
    
    span {
      display: block;
      width: 100%;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
      border-radius: 3px;
      height: 3px;
      background: #000;
      transition: all .3s;
      position: relative;
      & + span {
        margin-top: 1px;
      }
      
      &:nth-child(1) {
        animation: ease .7s top-2 forwards;
      }
      &:nth-child(2) {
        animation: ease .7s scaled-2 forwards;
      }
      &:nth-child(3) {
        animation: ease .7s bottom-2 forwards;
      }
    }
    
    &.js-active {
      span {
        &:nth-child(1) {
          animation: ease .7s top forwards;
        }
        &:nth-child(2) {
          animation: ease .7s scaled forwards;
        }
        &:nth-child(3) {
          animation: ease .7s bottom forwards;
        }
      }
    }
    
    @keyframes top {
      0% {
        top: 0;
        transform: rotate(0);
      }
      50% {
        //top: 22px;
        //top: 9px;
        transform: rotate(0);
      }
      100% {
        //top: 22px;
        //top: 9px;
        transform: rotate(45deg);
      }
    }
    
    @keyframes top-2 {
      0% {
        //top: 22px;
        //top: 9px;
        transform: rotate(45deg);
      }
      50% {
        //top: 22px;
        //top: 9px;
        transform: rotate(0deg);
      }
      100% {
        top: 0;
        transform: rotate(0deg);
      }
    }
    
    @keyframes bottom {
      0% {
        bottom: 0;
        transform: rotate(0);
      }
      50% {
        //bottom: 22px;
        bottom: 8px;
        transform: rotate(0);
      }
      100% {
        //bottom: 22px;
        bottom: 8px;
        transform: rotate(135deg);
      }
    }
    
    @keyframes bottom-2 {
      0% {
        //bottom: 22px;
        bottom: 8px;
        transform: rotate(135deg);
      }
      50% {
        //bottom: 22px;
        bottom: 8px;
        transform: rotate(0);
      }
      100% {
        bottom: 0;
        transform: rotate(0);
      }
    }
    
    @keyframes scaled {
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
    
    @keyframes scaled-2 {
      0% {
        transform: scale(0);
      }
      50% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    
  }
  
}

.header-wrapper {
  @media only screen and (min-width: 769px) {
    display: flex;
    flex-wrap: wrap;
  }
  .header {
    @media only screen and (min-width: 769px) {
      min-width: 100%;
    }
  }
  .banner {
    @media only screen and (min-width: 769px) {
      min-width: 100%;
      order: 2;
    }
  }
  .front-text {
    @media only screen and (min-width: 769px) {
      min-width: 100%;
      order: 3;
    }
  }
  .main-menu {
    @media only screen and (min-width: 769px) {
      min-width: 100%;
      order: 1;
    }
  }
}
.icon-word {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	color: $gray6;
	a {
		text-decoration: none;
		@include full_absolute;
	}
	&__top {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 3px;
		min-height: 15px;
	}
	&__count {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		background: $gray3;
		border-radius: 16px;
		padding: 0 5px;
		height: 15px;
		font-weight: 500;
		font-size: 12px;
		margin-left: 5px;
	}
	&__text {
		font-weight: 600;
		font-size: em(10);
		text-align: center;
	}
	.icon {
		@include trn();
	}
	
	//icon-word:hover
	&:hover {
		.icon {
			transform: scale(1.2);
		}
	}
}
//region Главное меню
.main-menu {
	background: #F6F6F6;
	
	@media only screen and (max-width: 930px) {
		padding-top: 5px;
	}
	
	@media only screen and (max-width: 768px) {
		position: fixed;
		left: 0;
		height: 80%;
		width: 100%;
		background: #fff;
		overflow: auto;
		min-width: 0;
		z-index: 9;
		//top: 130px;
		//height: calc(100% - 130px);
		top: 50px;
		height: calc(100% - 100px);
		opacity: 0;
		visibility: hidden;
		@include trn();
	}
	
	&_static,
	.front & {
		@media only screen and (max-width: 768px) {
			opacity: 1;
			visibility: visible;
			position: relative;
			top: 0;
			margin-bottom: 50px;
		}
	}
	
	&.js-active {
		opacity: 1;
		visibility: visible;
	}
	
	.container {
		display: flex;
		justify-content: space-between;
		//flex-wrap: wrap;
		min-height: em(100);
		
		align-items: center;
		
		@media only screen and (max-width: 930px) {
			flex-wrap: wrap;
		}
		
		@media only screen and (max-width: 768px) {
			display: block;
		}
		.main-menu_static & {
			display: block;
		}
	}
	
	&__item {
		max-width: 83px;
		text-align: center;
		position: relative;
		padding-top: 7px;
		min-height: 76px;
		text-decoration: none;
		@media only screen and (max-width: 930px) {
			min-width: 83px;
			min-width: calc(100% / 6);
		}
		
		@media only screen and (max-width: 768px) {
			min-width: 0;
			max-width: 100%;
			min-height: 0;
			height: em(55);
			display: flex;
			background: $gray6;
			border-radius: 4px;
			margin-bottom: em(16);
			align-items: center;
			flex-wrap: nowrap;
			padding: 0 em(10);
		}
		
		.main-menu_static & {
			min-width: 0;
			max-width: 100%;
			min-height: 0;
			height: em(55);
			display: flex;
			background: $gray6;
			border-radius: 4px;
			margin-bottom: em(16);
			align-items: center;
			flex-wrap: nowrap;
			padding: 0 em(10);
		}
		
		&.js-active {
			@media only screen and (max-width: 768px) {
				background-color: $gray2;
			}
		}
		
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	
	&__img {
		min-height: 41px;
		display: flex;
		//align-items: flex-end;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		img {
			max-height: 55px;
			@include trn();
		}
		
		@media only screen and (max-width: 768px) {
			width: em(35);
			margin-right: em(10);
			min-height: 0;
		}
		
		.main-menu_static & {
			width: em(35);
			margin-right: em(10);
			min-height: 0;
		}
	}
	
	&__title {
		font-size: em(12);
		color: $gray3;
		line-height: em(15);
		@media only screen and (max-width: 768px) {
			text-align: left;
			color: $gray1;
			font-weight: 700;
			font-size: em(20);
			line-height: em(24);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 0;
			max-width: 60%;
			max-width: calc(100% - #{em(90)});
		}
		
		.main-menu_static & {
			text-align: left;
			color: $gray1;
			font-weight: 700;
			font-size: em(20);
			line-height: em(24);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 0;
			max-width: 60%;
			max-width: calc(100% - #{em(90)});
		}
		
		.main-menu__item.js-active & {
			@media only screen and (max-width: 768px) {
				color: $gray5;
			}
		}
	}
	
	&__count {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 10px;
		color: $gray1;
		
		@media only screen and (max-width: 768px) {
			position: relative;
			background: $gray3;
			border-radius: 16px;
			padding: 2px 5px;
			margin-left: 14px;
			color: #fff;
			font-size: em(12);
			font-weight: 500;
		}
		
		.main-menu_static & {
			position: relative;
			background: $gray3;
			border-radius: 16px;
			padding: 2px 5px;
			margin-left: 14px;
			color: #fff;
			font-size: em(12);
			font-weight: 500;
		}
	}
}
//endregion
/* Plain HTML Version */
form.fivestar-widget {
	clear: both;
	display: block;
}
form.fivestar-widget select,
form.fivestar-widget input {
	margin: 0;
}

/* Combo Static and Rate Widget */
.fivestar-combo-stars .fivestar-static-form-item {
	float: left; /* RTL */
	margin-right: 40px; /* RTL */
	margin-left: 40px; /* RTL */
}
.fivestar-combo-stars .fivestar-static-item {
	float: left; /* RTL */
	margin-right: 40px; /* RTL */
}

.fivestar-combo-stars .fivestar-form-item {
	float: left; /* RTL */
}

/* Fivestar form items */
.fivestar-static-form-item .form-item,
.fivestar-form-item .form-item {
	margin: 0;
}

/* Static View-only Star Version */
div.fivestar-widget-static {
	display: block;
}

div.fivestar-widget-static br {
	clear: left; /* RTL */
}

div.fivestar-widget-static .star {
	float: left; /* RTL */
	width: 17px;
	height: 15px;
	overflow: hidden;
	text-indent: -999em;
	background: url(../widgets/default/star.gif) no-repeat 0 0; /* RTL */
}

div.fivestar-widget-static .star span.on {
	display: block;
	width: 100%;
	height: 100%;
	background: url(../widgets/default/star.gif) no-repeat 0 -32px; /* RTL */
}

div.fivestar-widget-static .star span.off {
	display: block;
	width: 100%;
	height: 100%;
	background: url(../widgets/default/star.gif) no-repeat 0 0; /* RTL */
}

/* Javascript Star Version */
div.fivestar-widget {
	display: block;
}

div.fivestar-widget .cancel,
div.fivestar-widget .star {
	float: left; /* RTL */
	width: 17px;
	height: 15px;
	overflow: hidden;
	text-indent: -999em;
}
div.fivestar-widget .cancel,
div.fivestar-widget .cancel a {
	background: url(../widgets/default/delete.gif) no-repeat 0 -16px; /* RTL */
	text-decoration: none;
}

div.fivestar-widget .star,
div.fivestar-widget .star a {
	background: url(../widgets/default/star.gif) no-repeat 0 0; /* RTL */
	text-decoration: none;
}

div.fivestar-widget .cancel a,
div.fivestar-widget .star a {
	display: block;
	width: 100%;
	height: 100%;
	background-position: 0 0; /* RTL */
	cursor: pointer;
}

div.fivestar-widget div.on a {
	background-position: 0 -16px; /* RTL */
}
div.fivestar-widget div.hover a,
div.rating div a:hover {
	background-position: 0 -32px; /* RTL */
}
form.fivestar-widget div.description {
	margin-bottom: 0;
}

html.js .fivestar-submit {
	display: none;
}

.fivestar-combo-stars .form-type-fivestar .form-item {
	float: left;
}
$browser-context: 16; // Default

@function em($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}
	
	@if (unitless($context)) {
		$context: $context * 1px;
	}
	
	@return $pixels / $context * 1em;
}

@function rem($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}
	
	@if (unitless($context)) {
		$context: $context * 1px;
	}
	@return #{$pixels/$context}rem;
}

// fonts
$arial: Arial, sans-serif;
$font_base: "Montserrat", "Helvetica", $arial;
//$font_second: "HarmoniaSansProCyr";

//$c_accent: #6C0068;
$c_accent: #4F4F4F;

// grid
$containerPadding: 15px;
$container: 1112px + $containerPadding*2; // That's where fluid becomes fixed
$base-v-margin: 20px; // The mother of all margins. Usually = base line height

// media
$media_desktop: "screen and (min-width: 1024px)"; // 1024-∞
$media_tablet: "screen and (max-width: 1023px)"; // 768-1024
$media_phone-landscape: "screen and (max-width: 767px)"; // 480-767 (large phones included, ipad vertical - not)
$media_phone: "screen and (max-width: 479px)"; // 320-479 (iphone 4 landscape not included)
$media_retina: "screen and (min-device-pixel-ratio: 1.5), screen and (-webkit-min-device-pixel-ratio: 1.5)";
$retina: "min-resolution: 1.5dppx";

// custom color
$c_purple: #a675f2;
$c_red: #EB5757;

// colors
$color_base: #333333;
$color_second: #666666;
$color_third: #666666;
$color_accent: #f46464;
$blue: #64a8f4;
$red: #f46464;
$green: #5fbc63;
$orange: #f4933d;
$fuchsia: #a675f2;
$base-border-radius: 4px;

// Grey
$gray1: #333333;
$gray2: #4F4F4F;
$gray3: #828282;
$gray4: #BDBDBD;
$gray5: #E0E0E0;
$gray6: #F2F2F2;



.flag-message {
	position: absolute;
	top: 1.7em;
	line-height: normal;
	left: 0;
	text-align: left;
	width: 300px;
	font-size: .8em;
}

.flag-message.flag-failure-message {
	border: 1px solid ;
	border-color: #ed5;
	color: #840;
	background-color: #fffce5;
	padding: 2px;
}

.flag-wrapper {
	position: relative;
}

/* Better contextual link support, prevent line wrapping. */
ul.contextual-links li .flag-wrapper a {
	display: inline-block;
}

ul.contextual-links li .flag-wrapper {
	display: block;
}

/* The rest deals with indicating the waiting state. */

.flag-waiting a {
	/* Give an impression of a disabled link. */
	opacity: 0.5;
	filter: alpha(opacity=50); /* IE */
}

.flag-waiting .flag-throbber {
	background: url(flag-throbber.gif) no-repeat right center;
	padding-right: 13px;
}
